import {createBrowserHistory} from 'history';
import config from '../config';
//import moment from 'moment';

export const history = createBrowserHistory( {basename: config.basename} );

export function isLoggedIn(){
    const loggedIn = JSON.parse(localStorage.getItem('user'));
    if (loggedIn){
        return true;
    }

    return false;
}