import * as actionTypes from './actions';
import config from './../config';

const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...config,
    isFullScreen: false, // static can't change
    cre8Connected: false,
    cre8Connecting: false,
    cre8Disconnecting: false,

    cre8StartStreamingRequest: false,
    cre8UpdateSceneRequest: false,
    cre8SettingRequest: false,

    cre8ProjectInfo: {name: "", videoSettingsData: {}, camera: 0, dmx:0, elevation: 0},
    cre8SettingsDialogShow: false,
    cre8LoadingSettings: false
};

const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
                trigger = (triggerIndex === -1) ? [action.menu.id] : [];
                open = (triggerIndex === -1) ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return {...state};
        case actionTypes.FULL_SCREEN :
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                s: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };
        case actionTypes.CRE8_CONNECT_SUCCESS:
            return{
                ...state,
                cre8Connected: true,
                cre8Connecting: false
            };
        case actionTypes.CRE8_CONNECT_REQUEST:
            return{
                ...state,
                cre8Connecting: true
            };
        case actionTypes.CRE8_DISCONNECT_SUCCESS:
            return {
                ...state,
                cre8Connected: false,
                cre8Disconnecting: false
            };
        case actionTypes.CRE8_DISCONNECT_REQUEST:
            return {
                ...state,
                cre8Disconnecting: true
            };
        case actionTypes.CRE8_START_STREAMING_REQEUST:
            return {
                ...state,
                cre8StartStreamingRequest: true
            };
        case actionTypes.CRE8_START_STREAMING_SUCCESS:
            return {
                ...state,
                cre8StartStreamingRequest: false
            };
        case actionTypes.CRE8_UPDATE_SCENE_REQUEST:
            return {
                ...state,
                cre8UpdateSceneRequest: true
            };
        case actionTypes.CRE8_UPDATE_SCENE_SUCCESS:
            return {
                ...state,
                cre8UpdateSceneRequest: false
            };
        case actionTypes.CRE8_SETTING_REQUEST:
            return {
                ...state,
                cre8SettingRequest: true
            }
        case actionTypes.CRE8_SETTING_SUCCESS:
            return{
                ...state,
                cre8SettingRequest: false
            }
        case actionTypes.CRE8_SET_PROGRAM_SCENE:
            return {
                ...state,
                cre8_scene_name: action.scene_name,
                cre8_studio_mode: action.studio_mode
            }
        case actionTypes.CRE8_PROJECT_INFO_UPDATE:
            return {
                ...state,
                cre8ProjectInfo: action.projectInfo
            }
        case actionTypes.CRE8_STATS_DATA_UPDATE:
            return {
                ...state,
                cre8StatsData: action.cre8StatsData        
            }
        case actionTypes.CRE8_SETTINGS_DIALOG_SHOW:
            return {
                ...state,
                cre8SettingsDialogShow: true,
                cre8LoadingSettings: true
            }
        case actionTypes.CRE8_SETTINGS_DIALOG_HIDE:
            return {
                ...state,
                cre8SettingsDialogShow: false
            }
        case actionTypes.CRE8_SETTINGS_DIALOG_SHOW_COMPLETED:
            return {
                ...state,
                cre8LoadingSettings: false
            }
        default:
            return state;
    }
};

export default reducer;