export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';

export const CRE8_CONNECT_REQUEST = 'CRE8_CONNECT_REQUEST';
export const CRE8_CONNECT_SUCCESS = 'CRE8_CONNECT_SUCCESS';
export const CRE8_DISCONNECT_REQUEST = 'CRE8_DISCONNECT_REQUEST';
export const CRE8_DISCONNECT_SUCCESS = 'CRE8_DISCONNECT_SUCCESS';

export const CRE8_START_STREAMING_REQEUST = "CRE8_START_STREAMING_REQEUST";
export const CRE8_START_STREAMING_SUCCESS = "CRE8_START_STREAMING_SUCCESS";

export const CRE8_UPDATE_SCENE_REQUEST = "CRE8_UPDATE_SCENE_REQUEST";
export const CRE8_UPDATE_SCENE_SUCCESS = "CRE8_UPDATE_SCENE_SUCCESS";

export const CRE8_SETTING_REQUEST = "CRE8_SETTING_REQUEST";
export const CRE8_SETTING_SUCCESS = "CRE8_SETTING_SUCCESS";

export const CRE8_SET_PROGRAM_SCENE = "SET_PROGRAM_SCENE";
export const CRE8_PROJECT_INFO_UPDATE = "CRE8_PROJECT_INFO_UPDATE";
export const CRE8_STATS_DATA_UPDATE = "CRE8_STATS_DATA_UPDATE";

export const CRE8_SETTINGS_DIALOG_SHOW = "CRE8_SETTINGS_DIALOG_SHOW"
export const CRE8_SETTINGS_DIALOG_HIDE = "CRE8_SETTINGS_DIALOG_HIDE"
export const CRE8_SETTINGS_DIALOG_SHOW_COMPLETED = "CRE8_SETTINGS_DIALOG_SHOW_COMPLETED"